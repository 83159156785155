

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root { 
  height: 100%;
}


.blueprint-pattern {
  background-image: linear-gradient(rgba(255,255,255,.5) 2px, transparent 2px),
  linear-gradient(90deg, rgba(255,255,255,.5) 2px, transparent 2px),
  linear-gradient(rgba(255,255,255,.2) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255,255,255,.2) 1px, transparent 1px);
  background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
  background-position: -30px -30px, -30px -30px, -8px -8px, -8px -8px;
}


.waves {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.waves svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 82px;
  transform: scaleX(1.2);
}


/* Define the scaling animation with percentage-based horizontal shift */
@keyframes scaleShift1 {
  0%, 100% {
    transform: translateX(0) scaleY(0.8);
  }
  50% {
    transform: translateX(-1%) scaleY(1);
  }
}

@keyframes scaleShift2 {
  0%, 100% {
    transform: translateX(0) scaleY(0.8);
  }
  50% {
    transform: translateX(1%) scaleY(1);
  }
}

@keyframes scaleShift3 {
  0%, 100% {
    transform: translateX(0) scaleY(0.8)
  }
  50% {
    transform: translateX(-0.5%) scaleY(1);
  }
}

/* Apply different animations to each path */
.waves path:nth-child(1) {
  animation: scaleShift1 6s ease-in-out infinite alternate;
}

.waves path:nth-child(2) {
  animation: scaleShift2 7s ease-in-out infinite alternate;
}

.waves path:nth-child(3) {
  animation: scaleShift3 5s ease-in-out infinite alternate;
}


.tilt {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.tilt svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 32px;
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .tilt svg {
      width: calc(100% + 1.3px);
      height: 82px;
  }
}