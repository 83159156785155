
.tooltip {
  position: relative; 
  top: 0px; 
  left: 0px;
  text-decoration: none;
  color: rgb(184, 51, 106);
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(244, 245, 247);
  z-index: 1000;
  padding: 1rem;
  top: -90px;
  left: -120px;     
  max-height: 150px;
  overflow-y: scroll;
  color: #3A3B3C;
  box-shadow: 10px 10px 10px -1px rgb(0 0 0 / 20%), 0px 10px 10px 0px rgb(0 0 0 / 14%), 0px 10px 15px 15px rgb(0 0 0 / 12%);
  height: auto;
  border-radius: 5px; 
  opacity: 0; 
  position: absolute;
  word-wrap: break-word;
  -webkit-transition: all 0.5s; 
     -moz-transition: all 0.5s; 
      -ms-transition: all 0.5s; 
       -o-transition: all 0.5s; 
          transition: all 0.5s;    
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1; 
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background: lightgrey;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 100vw;
  height: 1rem;
}
