
p > a.home {
  color: white;
  text-decoration: none;
}

p > a.home:hover {
  text-decoration: underline;
}

.footer {
  display: flex; 
  gap: 5rem; 
  justify-content: center; 
  padding-top: 5rem; 
  background: #23272a; 
  height: 300px; 
  margin: 2rem auto; 
  margin-bottom: 0px;
}

.footer-column {
  color: white; 
  max-width: 300px;
  font-family: Roboto;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0 1rem;
}

@media screen and (max-width: 1000px) {
  .footer {
    gap: 2rem; 
    text-align: center;
    padding-top: 2rem;
    height: 250px; 
  }
}

@media screen and (max-width: 750px) {
  .footer {
    gap: 0rem; 
    padding-top: 1rem; 
    height: 200px; 
    margin: 2rem auto; 
    margin-bottom: 0px;
  }
  
  .footer-column {
    font-size: .8rem;
    line-height: 1.2rem;
  }
}